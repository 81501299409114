@import 'styles/variables';
@import 'components/FeedPostImage/FeedPostImage.module';

$dateBgColor: rgba(black, 0.7);

.feedPost {
  background: #fff;
  border-radius: $borderRadius;
  box-shadow: none;
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.15s linear !important;
  margin-bottom: var(--gutter);

  &:last-child {
    margin-bottom: 0;
  }

  &.mockup {
    display: flex;
    flex-direction: column;
    position: relative;

    .content {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      margin-top: 10px;

      .mockupLine {
        background-color: $grey-lighter;
        border-radius: 10px;
        height: 10px;
        margin-bottom: 10px;
        width: 85%;

        &:nth-of-type(2) {
          width: 92%;
        }

        &:nth-of-type(3) {
          width: 100%;
        }
      }
    }
  }
}

.imgWrapper {
  @extend .imgWrapper;
  background-color: $black;
}

.label {
  background-color: $dateBgColor;
  border-radius: $borderRadius;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 2px 4px;
  text-transform: uppercase;

  &.floatingLabel {
    bottom: 0;
    left: 12px;
    position: absolute;
  }

  &.right {
    left: initial;
    right: 12px;
  }
}

.epl {
  background-color: rgba($eplRed, 0.7);
  color: #fff;
}

.content {
  color: $black !important;
  font-family: inherit;
  font-size: 13px;
  line-height: 1.3;
  margin: 4px 0 0;
  padding: 12px;
  white-space: pre-wrap;
  word-break: break-word;
}

.footer {
  align-items: flex-end;
  border-top: 1px solid #e7e8ec;
  clear: both;
  color: rgb(130, 138, 153);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin: 0 12px;
  padding: 8px 0;

  .svgIcon {
    height: 24px;
    margin-right: 6px;
    opacity: 0.7;
    width: 24px;
  }

  .likes,
  .comments {
    align-items: center;
    display: flex;
    flex: 0 0 5%;
    margin-right: 20px;
  }

  .views {
    align-items: center;
    color: #909399;
    display: flex;
    flex: 1 1 50%;
    font-size: 12px;
    justify-content: flex-end;

    .svgIcon {
      height: 10px;
      margin-right: 4px;
      width: 14px;
    }
  }
}
