@import 'styles/variables.scss';

.desktop {
  --gutter: #{$gutter-desktop};

  :global {
    .feedPost {
      border-radius: $borderRadius;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
      overflow: hidden;

      &:hover {
        box-shadow: 0 14px 28px rgba(214, 214, 214, 0.25),
          0 10px 10px rgba(140, 140, 140, 0.22);

        .feedPostImage {
          filter: saturate(1);
          opacity: 1;
        }
      }
    }

    .feedPost__content {
      padding: 12px 20px;
    }

    .feedPost__left {
      left: 20px !important;
    }

    .feedPost__right {
      left: initial !important;
      right: 20px !important;
    }

    .feedPost__footer {
      margin: 0 20px;
      padding: 12px 0;
    }
  }
}
