@import "styles/variables";

@keyframes blinking {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.imgWrapper {
  background: $blue-dark;
  color: white;
  min-height: 150px;
  position: relative;
  text-align: center;
  width: 100%;

  &.mockup {
    background: $black;
  }

  &.video {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &.vertical {
    border-radius: 0 0 $borderRadius 0;
    float: left;
    justify-content: center;
    margin-right: 12px;
    overflow: hidden;
    width: 100px;
  }

  .svgIcon {
    animation: blinking 1s linear infinite alternate;
    color: $grey;
    margin: 30px 0;
    width: 50%;
  }
}

.postImage {
  display: block;
  filter: saturate(0.9);
  height: auto;
  object-fit: cover;
  object-position: top;
  opacity: 0.85;
  transition: all 0.15s linear;
  width: 100%;

  &.hidden {
    display: none;
  }
}
